/*
 * iiimagine-frontend - ReadMore.component.tsx
 * ReadMore.component.tsx
 *
 * Created by Renzo Roso (renzo@3tdev.nl)
 * Copyright 3T Development - 2022-01-27
 */

import React, {HTMLProps, useCallback, useState} from 'react';
import classNames from "classnames";
import {FaChevronRight, FaChevronUp} from 'react-icons/fa';

interface ReadMoreComponentProps extends HTMLProps<HTMLDivElement> {
    more: JSX.Element;
}

export default function ReadMoreComponent({children, className, more}: ReadMoreComponentProps) {
    const [isOpened, updateIsOpened] = useState(false);

    const toggleOpen = useCallback(() => {
        updateIsOpened(!isOpened);
    }, [isOpened])

    return (
        <>
            <div className={classNames('hidden md:flex md:flex-row gap-8 text-base lg:text-lg', className)}>
                {children}
                {more}
            </div>
            <div className={classNames("md:hidden", className)}>
                <div className={classNames("flex md:hidden flex-col text-base")}>
                    {children}
                </div>
                <div
                    className={classNames("relative transition-[max-height] ease-in-out duration-500 overflow-y-hidden flex flex-col max-h-0 gap-8 mb-8", isOpened && '!max-h-[150rem]')}>
                    <div className="mt-8 flex flex-col gap-8 mb-4 text-base">
                        {
                            more
                        }
                    </div>
                </div>
                <button onClick={toggleOpen}
                        className={classNames("text-lg text-iiimagine-pink flex items-center pl-2 gap-2 -mt-4")}>
                    {isOpened ? (
                        <>
                            <FaChevronUp/> Read less
                        </>
                    ) : (
                        <>
                            <FaChevronRight/> Read more
                        </>
                    )}
                </button>
            </div>
        </>

    )
};
