/*
 * iiimagine-frontend - Jobs.component.tsx
 * Jobs.component.tsx
 *
 * Created by Renzo Roso (renzo@3tdev.nl)
 * Copyright 3T Development - 2022-02-22
 */

import React from 'react';
import SmudgeBannerComponent from "../SmudgeBanner/SmudgeBanner.component";
import {jobs} from "./Jobs";
import {SingleJobComponent} from "./SingleJob.component";

export default function JobsComponent() {

    return (
        <>
            <SmudgeBannerComponent type={"jobs"} id={"jobs"}/>
            <p className="w-full md:w-3/5 py-8 text-lg md:text-xl">We’re looking for new talent to build on the dream
                with us. Feel free to contact us or have a look at our open vacancies:</p>
            <div className="grid grid-flow-row grid-rows-auto divide-y divide-dashed divide-white/50">
                {
                    jobs.map((job, index) => (
                        <SingleJobComponent key={`${index}-${job.title}`} job={job}/>
                    ))
                }
            </div>
        </>
    )
}
