/*
 * iiimagine-frontend - Arr.ts
 * Arr.ts
 *
 * Created by Renzo Roso (renzo@3tdev.nl)
 * Copyright 3T Development - 2022-01-27
 */

export function addEveryOther(original: any[], toAdd: any) {
    const arr = [];
    const copy = [...original];
    while (copy.length > 0) {
        const item = copy.shift();
        if (!item) {
            break;
        }

        if (arr.length !== 0) {
            arr.push(toAdd)
        }

        arr.push(item)
    }

    return arr;
}
