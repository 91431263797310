/*
 * iiimagine-frontend - List.component.tsx
 * List.component.tsx
 *
 * Created by Renzo Roso (renzo@3tdev.nl)
 * Copyright 3T Development - 2022-01-27
 */

import React, {useMemo} from 'react';

interface ListComponentProps {
    type?: 'ol' | 'ul';
    items: string[]
}

export default function ListComponent({type, items = []}: ListComponentProps) {

    const list = useMemo(() => {
        return items.map((item, i) => (
            <li key={`${item.substring(0, 10)}-${i}`}
                className="pl-8 relative before:absolute before:left-0 before:content-['◦']">
                {item}
            </li>
        ));
    }, [items])

    switch (type) {
        case 'ol':
            return (
                <ol className="list-none">{list}</ol>
            )
        case 'ul':
        default:
            return (
                <ul>{list}</ul>
            )
    }
};
