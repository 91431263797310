/*
 * iiimagine-frontend - SingleJob.component.tsx
 * SingleJob.component.tsx
 *
 * Created by Renzo Roso (renzo@3tdev.nl)
 * Copyright 3T Development - 2022-09-22
 */

import React, {useCallback, useState} from "react";
import ContentDivider from "../../assets/images/Content-Divider.png";
import classNames from "classnames";
import ListComponent from "../List/List.component";
import ReactPlayer from "react-player/file";
import {Job} from "./Jobs";

type SingleJobComponentProps = {
    job: Job;
}

export function SingleJobComponent({job}: SingleJobComponentProps) {
    const [isOpened, updateIsOpened] = useState(false);

    const toggleOpen = useCallback(() => {
        updateIsOpened((!isOpened));
    }, [isOpened])

    return (
        <div className="pb-4">
            <div className={"pt-8 pb-4 text-lg md:text-xl lg:text-[28px] flex justify-between items-center"} onClick={toggleOpen}>
                <div className={'flex-1 flex items-center'}>

                <img src={ContentDivider} height={"14px"} width={"14px"} className={"inline"} alt={"divider"}/>
                <span className={"mx-4"}>{job.title}</span>
                </div>

                <button className={"text-3xl "}>
                    {isOpened ? '—' : '+'}
                </button>
            </div>

            <div className={
                classNames("relative transition-[max-height] ease-in-out duration-500 overflow-y-hidden flex flex-col gap-8 max-h-0", "flex flex-col gap-2", isOpened && '!max-h-[150rem]')}>
                {
                    job.description
                }

                {
                    job.period && (
                        <>
                            <p className="font-bold text-lg mt-4">Period</p>
                            <p>
                                {job.period}
                            </p>
                        </>
                    )
                }

                <p className="font-bold text-lg mt-4">Your role</p>

                <ListComponent items={job.whatYouWillBeDoing}/>

                <p className="font-bold text-lg mt-4">Requirements</p>

                <ListComponent items={job.requirements}/>

                {job.video && (

                    <div className="mt-4 aspect-video w-full md:w-3/4 lg:w-1/2">

                        <ReactPlayer
                            url={job.video.url}
                            width={"100%"}
                            height={"100%"}
                            light={job.video.thumbnail}
                            playing
                            controls
                        />
                    </div>
                )}
                <div className="mt-4 pb-4">
                    {job.interested}
                </div>
            </div>
        </div>

    )
}