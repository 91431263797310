/*
 * iiimagine-frontend - Logo.component.tsx
 * Logo.component.tsx
 *
 * Created by Renzo Roso (renzo@3tdev.nl)
 * Copyright 3T Development - 2022-01-26
 */

import React from 'react';
import Logo from './../../assets/images/Logo.png';


export default function LogoComponent () {
    return (
        <img src={Logo} alt="Logo" className="my-16 h-auto w-3/4 md:w-1/2 lg:w-auto "/>
    )
};
