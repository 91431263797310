/*
 * iiimagine-frontend - App.tsx
 * App.tsx
 *
 * Created by Renzo Roso (renzo@3tdev.nl)
 * Copyright 3T Development - 2022-01-24
 */

import React from "react";
import "./App.css";
import FullScreen from "./components/FullScreen/FullScreen.component";
import MenuBarComponent from "./components/MenuBar/MenuBar.component";
import LogoComponent from "./components/Logo/Logo.component";
import SmudgeBannerComponent from "./components/SmudgeBanner/SmudgeBanner.component";
import PreviousShowsComponent from "./components/PreviousShows/PreviousShows.component";
import WhiteButtonComponent from "./components/WhiteButton/WhiteButton.component";
import JobsComponent from "./components/Jobs/Jobs.component";
import CopyrightComponent from "./components/Copyright/Copyright.component";
import AboutComponent from "./components/About/About.component";

function App() {
  return (
    <div className="snap-y overflow-y-hidden scroll-smooth bg-black text-white font-body ">
      <MenuBarComponent
        menu={[
          { title: "III Shows", link: "#past-shows" },
          { title: "Jobs", link: "#jobs" },
          { title: "Contact", link: "mailto:info@iiimagine.org" },
        ]}
      />

      <FullScreen header id="header">
        <div className="pt-8 ">
          <LogoComponent />

          <h1 className="text-xl md:text-[28px] lg:text-3xl font-bold uppercase font-curtisalto">
            <span className="flex">
              A reflection of the moment
              <br /> in art and sound
            </span>
          </h1>

          <p className="w-full md:w-3/4 lg:w-1/2 my-8 text-lg md:text-xl">
            IIIMAGINE organises extraordinary shows where new electronic music &
            visuals are created by multidisciplinary live act iii.
          </p>
          <p className="w-full md:w-3/4 lg:w-1/2 my-8 text-lg md:text-xl">
            Imagine a mirror: we create its framework by blending art with
            technology and you shape the reflection. IIIMAGINE is a celebration
            of the moment, which you can only live at that time & place. A
            moment imbued with a sense of otherworldliness through electronic
            elements and atmospheric sounds. We strive to imprint the memories
            of the shows on your mind for the next 50 years.
          </p>

          <WhiteButtonComponent href={"#past-shows"}>
            past iii shows
          </WhiteButtonComponent>
        </div>
      </FullScreen>

      <FullScreen className="pt-16 lg:pt-24">
        <SmudgeBannerComponent type={"shows"} id={"past-shows"} />
        <p className="mt-5 text-lg md:text-xl">
          Here's a selection of our previous IIIMAGINE shows
        </p>
      </FullScreen>
      <PreviousShowsComponent />
      <FullScreen className="scroll-mt-8">
        <JobsComponent />
      </FullScreen>
      <FullScreen>
        <AboutComponent />
      </FullScreen>
      <FullScreen>
        <CopyrightComponent />
      </FullScreen>
    </div>
  );
}

export default App;
