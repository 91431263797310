/*
 * iiimagine-frontend - WhiteButton.component.tsx
 * WhiteButton.component.tsx
 *
 * Created by Renzo Roso (renzo@3tdev.nl)
 * Copyright 3T Development - 2022-01-27
 */

import React, {HTMLProps} from 'react';
import classNames from "classnames";

export default function WhiteButtonComponent(props: HTMLProps<HTMLButtonElement>): JSX.Element;
export default function WhiteButtonComponent(props: HTMLProps<HTMLAnchorElement>): JSX.Element;
export default function WhiteButtonComponent(props: any): JSX.Element {
    const classes = classNames(
        "text-sm font-space-grotesk transition-colors ease-in-out duration-200 inline-block bg-white rounded-full px-[25px] py-4 uppercase text-black font-bold hover:bg-iiimagine-pink hover:text-white",
        props.className || ''
    );

    if (props.href) {
        return (
            <a href={props.href} className={classes}>{props.children}</a>
        )
    }

    return (
        <button onClick={props.onClick} className={classes}>{props.children}</button>
    )
};
