/*
 * iiimagine-frontend - BirdPrefixTitle.component.tsx
 * BirdPrefixTitle.component.tsx
 *
 * Created by Renzo Roso (renzo@3tdev.nl)
 * Copyright 3T Development - 2022-01-27
 */

import React from 'react';
import classFix from "../../lib/Str";

interface BirdPrefixTitleComponentProps {
    prefix: string;
    title: string;
}

export default function BirdPrefixTitleComponent({prefix, title}: BirdPrefixTitleComponentProps) {

    return (
        <div className="flex">

            <div className={"w-full"}>
                            <span className={classFix(`
                            z-10
                            hidden
                            md:inline md:relative md:-ml-6 text-xs
                            -ml-4
                            leading-none
                            bottom-0
                            pr-2
                             
                            lg:text-3xl
                            lg:-ml-14
                            lg:pr-4
                            
                            before:w-full
                            before:h-full
                            before:absolute
                            before:-ml-4
                            before:mt-5
                            before:lg:mt-4
                            before:lg:-ml-4
                            before:bg-1/2
                            before:bg-no-repeat
                            before:bg-[url("assets/images/bird.png")]
                            before:content-['']
                            `)}>
                                {prefix}
                            </span>

                <span className={classFix(`
                            text-[28px]
                            md:text-[32px]
                            font-bold
                            leading-none
                            relative
                            inline
                           
                        `)}>
                                {title}
                            </span>

            </div>
        </div>
    )
};
