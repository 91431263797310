/*
 * iiimagine-frontend - Copyright.component.tsx
 * Copyright.component.tsx
 *
 * Created by Renzo Roso (renzo@3tdev.nl)
 * Copyright 3T Development - 2022-01-27
 */

import React from 'react';

export default function CopyrightComponent() {

    return (
        <div className={"my-24 flex justify-center items-center text-base md:text-xl"}>
            <span>© iiimagine {new Date().getFullYear()} - All rights reserved </span>
        </div>
    )
};
