/*
 * iiimagine-frontend - FullScreen.component.tsx
 * FullScreen.component.tsx
 *
 * Created by Renzo Roso (renzo@3tdev.nl)
 * Copyright 3T Development - 2022-01-24
 */

import React, {HTMLProps, useMemo} from 'react';
import classNames from 'classnames';

interface FullScreenProps extends HTMLProps<HTMLDivElement> {
    children: React.ReactNode
    header?: boolean
    id?: string
    containerClassName?: string
}

function FullScreen({children, header = false, id, className, containerClassName}: FullScreenProps) {
    const bgImage = useMemo(() => {
        if (header) {
            return 'min-h-screen bg-header-mobile md:bg-header-tablet lg:bg-header-desktop'
        }
        return 'bg-none sm:bg-dust'
    }, [header])


    return (
        <div className={classNames('bg-cover bg-no-repeat bg-right-top', bgImage, className)} id={id}>
            <div className={classNames("container mx-auto py-8", containerClassName)}>
                <div className="px-8 sm:px-12 md:px-16">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default FullScreen;

