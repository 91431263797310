/*
 * iiimagine-frontend - SmudgeBanner.component.tsx
 * SmudgeBanner.component.tsx
 *
 * Created by Renzo Roso (renzo@3tdev.nl)
 * Copyright 3T Development - 2022-01-26
 */

import React, {HTMLProps, useMemo} from 'react';
import classNames from "classnames";

interface H2ComponentProps extends HTMLProps<HTMLDivElement>{
    type: 'shows' | 'about' | 'jobs' | 'sold-out';

}

export default function SmudgeBannerComponent({type, ...props}: H2ComponentProps) {
    const margins = useMemo(() => {
        switch (type) {
            case 'sold-out':
                return '-ml-2 pl-2';
            case 'shows':
            case 'about':
            case 'jobs':
            default:
                return '-ml-6 pl-6'

        }
    }, [type])

    const textSize = useMemo(() => {
        switch (type) {
            case 'sold-out':
                return 'text-[8px] md:text-xxs leading-none';
            case 'shows':
            case 'about':
            case 'jobs':
            default:
                return 'text-2xl md:text-3xl';

        }
    }, [type])

    const textColor = useMemo(() => {
        switch (type) {
            case 'sold-out':
                return 'text-white';
            case 'shows':
            case 'about':
            case 'jobs':
            default:
                return 'text-black'
        }
    }, [type])


    const title = useMemo(() => {
        switch (type) {
            case 'sold-out':
                return 'Sold out'
            case 'shows':
                return 'iiimagine shows';
            case 'about':
                return 'about';
            case 'jobs':
                return 'jobs';
        }
    }, [type])

    const width = useMemo(() => {
        switch (type) {
            case 'sold-out':
                return 'w-[57px] h-[17px]'
            case 'shows':
                return 'w-[278px] h-[41px]';
            case 'about':
                return 'w-[143px] h-[41px]';
            case 'jobs':
                return 'w-[118px] h-[41px]';
        }
    }, [type])

    const bg = useMemo(() => {
        switch (type) {
            case 'sold-out':
                return 'bg-pink-smudge'
            case 'shows':
                return 'bg-shows-smudge'
            case 'about':
                return 'bg-jobs-smudge'
            case 'jobs':
                return 'bg-jobs-smudge'
        }
    }, [type])

    return (
        <div className={classNames("flex items-center ") } {...props}>
            <div
                className={classNames("flex bg-cover items-center", width, margins, bg)}>

                <p className={classNames("uppercase inline font-curtisalto", textColor, textSize,)}>
                    {title}
                </p>
            </div>
        </div>
    )
};
