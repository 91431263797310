/*
 * iiimagine-frontend - Str.ts
 * Str.ts
 *
 * Created by Renzo Roso (renzo@3tdev.nl)
 * Copyright 3T Development - 2022-01-31
 */

export default function classFix(input: string) {
    return input.split('\n').join(' ').replace(/( )+/g, ' ');
}
