/*
 * iiimagine-frontend - previousShows.tsx
 * previousShows.tsx
 *
 * Created by Roelandt Brothers
 */

import React from "react";
import CastleGravenSteen from "../../assets/images/castle-gravensteen.png";
import RubensHouse from "../../assets/images/rubens-house.png";
import VictorHorta from "../../assets/images/victor-horta.png";
import IIIHotel from "../../assets/images/iii-hotel.png";
import IIIAmsterdam from "../../assets/images/iii-amsterdam.jpg";
import IIIMAGINExSantiagoCalatrava from "../../assets/images/IIIMAGINE-x-Santiago-Calatrava.jpg";
import IIISheepvaartMuseum from "../../assets/images/iii-Het-scheepvaartmuseum.jpg";

import IIIAtomium from "../../assets/images/iii-Atomium.jpg";


// import IIIZahaHadid from "../../assets/images/iii-Zaha-Hadid-port-house.jpg";
import IIIZahaHadidA from "../../assets/images/iii-zaha-01.jpg";
import IIIZahaHadidB from "../../assets/images/iii-zaha-02.jpg";


import IIICentralStation from "../../assets/images/iii-Central-station.jpg";
import IIICentralStationAction from "../../assets/images/iii-Central-station-action.jpg";

import IIITheHague from "../../assets/images/iiimagine-CityHall-TheHague.jpg";
import IIIACQUARIO from "../../assets/images/IIIMAGINE-at-Acquario-Romano.jpg";

interface ShowHeader {
  label: string;
  order: number[];
  title: string;
  subtitle: string;
}

interface ShowHeaderVideo extends ShowHeader {
  video: {
    url: string;
    thumbnail: string;
    alt: string;
  };
}

interface ShowHeaderImage extends ShowHeader {
  image: {
    src: string;
    alt: string;
    horizontal?: boolean;
  };
}

interface ShowHeaderGallery extends ShowHeader {
  images: {
    src: string;
    alt: string;
  }[];
}

interface BasePreviousShow {
  content?: {
    main: JSX.Element;
    more: JSX.Element;
  };
}
export interface PreviousShowVideo extends BasePreviousShow {
  header: ShowHeaderVideo;
}

export interface PreviousShowImage extends BasePreviousShow {
  header: ShowHeaderImage;
}

export interface PreviousShowGallery extends BasePreviousShow {
  header: ShowHeaderGallery;
}

export type PreviousShow =
  | PreviousShowVideo
  | PreviousShowImage
  | PreviousShowGallery;
export type PreviousShows = Array<PreviousShow>;

export function isShowVideo(input: any): input is PreviousShowVideo {
  return !!input.header.video;
}

export function isShowImage(input: any): input is PreviousShowImage {
  return !!input.header.image;
}

export function isShowGallery(input: any): input is PreviousShowGallery {
  return !!input.header.images;
}

export function isImageVertical(input: any): input is PreviousShowImage {
  return input.header.image && !input.header.image.horizontal;
}

const shows: PreviousShows = [

  {
    header: {
      label: "Sold out",
      order: [1, 2],
      title: "Atomium in Brussels",
      subtitle:
        "In front of the emblematic Atomium, IIIMAGINE landed with its audiovisual show, on the occasion of the monument's 66th birthday.",
      image: {
        src: IIIAtomium,
        alt: "IIIMAGINE Atomium",
      },
    },
    content: {
      main: (
        <span className="flex-1">
          Our two faceless characters in a performance of music, visuals, and lights. 
            We created a show that aimed to harmonize with the Atomium's futuristic aesthetic, 
            through the use of atmospheric textures and innovative sound and visual design. 
        </span>
      ),
      more: (
        <>
          <span className="flex-1">
          A show at Belgium’s most iconic landmark, the Atomium, depicting nine atoms forming an iron crystal.
          </span>
          <span className="flex-1">
            We incorporated historical recordings, speeches, and musical fragments, 
            all absorbed by iii and showcased through our vision.
            
          </span>
        </>
      ),
    },
  },


  {
    header: {
      label: "Sold out",
      order: [1, 2],
      title: "Central Station",
      subtitle:
        "Hailed as the most beautiful railway station in the world, IIIMAGINE presented its concert in the Antwerp Central Station.",
      images: [
        {
          src: IIICentralStationAction,
          alt: "IIIMAGINE Central Station in action",
        },
        {
          src: IIICentralStation,
          alt: "IIIMAGINE Central Station",
        },
      ],
    },
    content: {
      main: (
        <span className="flex-1">
          For this special occasion, the monumental Hall, which welcomes an
          average of 33,000 travelers per day, was sealed off and no entry was
          possible unless you were attending the IIIMAGINE show.
        </span>
      ),
      more: (
        <>
          <span className="flex-1">
            The first trains connected different regions, redefined distances,
            and radiated power, romance & fire. This idea served as our starting
            point to make new music and a new light show for this evening.
          </span>
          <span className="flex-1">
            Think of a time when railway stations were built with the same
            assurance as castles and cathedrals in the centuries before. The
            undisputed pinnacle is Antwerp Central Station, also known as the
            Railway Cathedral. We wanted to draw a parallel with today’s world
            which is on the verge of new technologies & forms of art.
          </span>
        </>
      ),
    },
  },
  {
    header: {
      label: "Sold out",
      order: [1, 2],
      title: "National Maritime Museum in Amsterdam",
      subtitle:
        "For the first time in 10 years, Het Scheepvaartmuseum opened its doors for two special evenings.",
      image: {
        src: IIISheepvaartMuseum,
        alt: "IIIMAGINE Het Scheepvaartmuseum",
      },
    },
    content: {
      main: (
        <span className="flex-1">
          Elected as best museum in The Netherlands, our inspiration for
          IIIMAGINE in Het Scheepvaartmuseum started from the drive to discover
          and to adventure. A journey to new places, whether this is the sea,
          land, space, or somewhere in your mind where you haven’t been before.
        </span>
      ),
      more: (
        <>
          <span className="flex-1">
            Discovery of the ocean required a complete surrender to the sea, an
            unwavering trust in people accompanying you, and also in the ship
            sailing to new horizons. A journey of embracing new ways and the
            feeling of togetherness.
          </span>
          <span className="flex-1">
            Through the creation of music, visuals and light, IIIMAGINE tried to
            capture these feelings. The image shows an interpretation of the
            vision we wanted to convey.
          </span>
        </>
      ),
    },
  },
  {
    header: {
      label: "Sold out",
      order: [1, 2],
      title: "Zaha Hadid Port House",
      subtitle:
        "A show in dialogue with the stunning architectural masterpiece by Zaha Hadid.",
      // image: {
      //   src: IIIZahaHadid,
      //   alt: "IIIMAGINE Zaha Hadid",
      // },
      images: [
        {
          src: IIIZahaHadidA,
          alt: "IIIMAGINE Zaha Hadid in action",
        },
        {
          src: IIIZahaHadidB,
          alt: "IIIMAGINE Zaha Hadid",
        },
      ],
    },
    content: {
      main: (
        <span className="flex-1">
          IIIMAGINE presented an audiovisual show which started from the
          building’s unique juxtaposition of old & new architecture: an immense
          contemporary vessel floating above a derelict fire station.
        </span>
      ),
      more: (
        <>
          <span className="flex-1">
            Our music tried to capture Zaha Hadid's characteristic organic
            shapes and fluid lines, by modulators, blending synth patches,
            atmospheric samples, and field recordings of the port.
          </span>
          <span className="flex-1">
            Think visually of a surrealist interpretation: will the building be
            submerged in an underwater environment, or will the underwater
            environment rise above the surface?
          </span>
        </>
      ),
    },
  },

  {
    header: {
      label: "Sold out",
      order: [1, 2],
      title: "Acquario Romano in Rome, Italy",
      subtitle:
        "For the first time ever, this palace in the bustling heart of Rome opened up for an evening of electronic music and visuals.",
      image: {
        src: IIIACQUARIO,
        alt: "IIIMAGINE Acquario Romano Rome",
      },
    },
    content: {
      main: (
        <span className="flex-1">
          Situated between the Basilica di Santa Maria Maggiore and the
          Colosseum, this neoclassical palace inspired us with its delicate
          balance between marine and terrestrial life. Through music, visuals,
          and light, IIIMAGINE aimed to capture this balance. The image
          represents an interpretation of the vision we sought to convey.
        </span>
      ),
      more: (
        <>
          <span className="flex-1">
            Additionally, the elliptical structure of the Acquario Romano,
            characterized by its elegant pillars and harmonious lines,
            influenced a creation that reflects these features.
          </span>
        </>
      ),
    },
  },

  {
    header: {
      label: "Sold out",
      order: [1, 2],
      title: "City Hall of The Hague",
      subtitle:
        "In an unprecedented move, the official city hall of The Hague opened its doors as the setting for our audiovisual show, marking a significant exception to its conventional function.",
      image: {
        src: IIITheHague,
        alt: "IIIMAGINE The Hague City Hall",
      },
    },
    content: {
      main: (
        <span className="flex-1">
          Our inspiration for this show in the City Hall of The Hague (Den Haag)
          started from architect Richard Meier’s interplay of light and spatial
          dynamics within his characteristic white structures. Just as the white
          facades of the city hall are seen as a blank canvas, so too did we
          install our own version of a white canvas in the city hall - with the
          help of technology and projectors. During the live show, our canvas
          sprang to life with colors and emotions.
        </span>
      ),
      more: (
        <>
          <span className="flex-1">
            Our work oscillated between abstract and figurative forms, building
            on IIIMAGINE’s vision while engaging in a dialogue with the
            modernity of the city hall’s design. This was interpreted through
            the medium of 3D visuals, music, and light.
          </span>
        </>
      ),
    },
  },

  {
    header: {
      label: "Sold out",
      order: [1, 2],
      title: "Aviation Hall",
      subtitle:
        "For the first time ever, a concert was allowed in the Royal Museum of the Armed Forces and Military History.",
      video: {
        url: "assets/video/TRAILER-CURTIS-ALTO.mp4",
        thumbnail: "assets/images/aviation-hall.png",
        alt: "Aviation Hall",
      },
    },
    content: {
      main: (
        <span className="flex-1">
          {" "}
          Humans have been dreaming about being able to fly for thousands of
          years. Whether it was the myth of Icarus in Ancient Greece or the
          scientific endeavour of Leonardo Da Vinci, looking at the birds and
          coming up with revolutionary technologies.
        </span>
      ),
      more: (
        <>
          <span className="flex-1">
            {" "}
            When you close your eyes, you should feel the freedom of flying, the
            heroism of the aviation pioneers, the virtue of trying just one more
            time and the possibilities of technology.
          </span>
          <span className="flex-1">
            {" "}
            IIIMAGINE presented two consecutive editions in the Aviation Hall
            where new music was composed, visuals were developed and an
            impressive light show was designed, symbolizing this human dream of
            flight.
          </span>
        </>
      ),
    },
  },
  {
    header: {
      label: "Sold out",
      order: [2, 1],
      title: "Royal Museum of Fine Arts",
      subtitle:
        "For the first time in ten years, the Royal Museum of Fine Arts Antwerp (KMSKA) exceptionally opened its doors again.",
      video: {
        url: "assets/video/IIIMAGINE-x-KMSKA-Story.mp4",
        thumbnail: "assets/images/royal-museum-of-fine-arts.png",
        alt: "Royal Museum of Fine arts",
      },
    },
    content: {
      main: (
        <span className="flex-1">
          IIIMAGINE presented three nights in Royal Museum of Fine Arts where
          new music was composed, inspired by the axes of time coming together
          in the superb central gallery of the Museum, the literal gateway to
          the old and the new.
        </span>
      ),
      more: (
        <>
          <span className="flex-1">
            The Gallery serves as the Museum’s aorta: you are absorbed by the
            splendor and larger-than-life paintings portraying 16th-17th century
            greats like Rubens and Van Dyck. At the same time, you find yourself
            in front of the monumental doors accessing both the new futuristic
            wing and the classic 19th century architecture.
          </span>
          <span className="flex-1">
            Before the Museum reopened its doors, these evenings served as a
            unique glimpse into the monumental changes that have taken place
            over the course of the last 10 years.
          </span>
        </>
      ),
    },
  },
  {
    header: {
      label: "Sold out",
      order: [1, 2],
      title: "Calatrava’s Liège-Guillemins station",
      subtitle:
        "For the first time since its inauguration, a concert was allowed inside Santiago Calatrava’s iconic building.",
      image: {
        src: IIIMAGINExSantiagoCalatrava,
        alt: "Castle Gravensteen",
        horizontal: true,
      },
    },
    content: {
      main: (
        <span className="flex-1">
          The railway station of Liège-Guillemins is a futuristic masterpiece
          from glass and steel, designed by the world-renowned architect
          Santiago Calatrava. With its monumental arched roof, the station is
          arguably Belgium's most iconic modern building.
        </span>
      ),
      more: (
        <>
          <span className="flex-1">
            IIIMAGINE presented a show with new music & new visuals. How to
            connect human, train and building while relying on an absence of
            facades? The railway station was seen as a living being: steel
            arches rising up high - giving one the impression of ribs, and the
            constant flow of people and trains passing by.
          </span>
          <span className="flex-1">
            Sounds representing machines handling the coordination of the
            trains. Modulated synthesisers likening the repetition of the
            building’s steel arches. Yet also human sounds linking the constant
            flow of people to the effect the sheer beauty of the station has on
            them.
          </span>
        </>
      ),
    },
  },
  {
    header: {
      label: "Sold out",
      order: [1, 2],
      title: "Castle Gravensteen",
      subtitle:
        "IIIMAGINE held the first music show in 20 years in this medieval castle.",
      image: {
        src: CastleGravenSteen,
        alt: "Castle Gravensteen",
        horizontal: true,
      },
    },
    content: {
      main: (
        <span className="flex-1">
          IIIMAGINE composed music inspired by the ancient legends of Count
          Boudewijn I, who started the castle’s first constructions back in 850.
        </span>
      ),
      more: (
        <>
          <span className="flex-1">
            In the show two ancient legends were translated into new electronic
            music: (i) the medieval forbidden love story of Boudewijn and Judith
            with their escape to the North. And (ii) the mythical fight between
            Boudewijn and a brown bear.
          </span>
          <span className="flex-1">
            The show resulted into electronic music characterised by sampling of
            clattering swords and spears, sounds capturing the heroic exertion
            and our iii choir performing the new soundtrack live.
          </span>
        </>
      ),
    },
  },
  {
    header: {
      label: "Sold out",
      order: [1, 2],
      title: "Rubens House",
      subtitle:
        "Translating particular scenes of the grandmaster Rubens and Boeckhorst artworks into new music.",
      image: {
        src: RubensHouse,
        alt: "Rubens House",
      },
    },
  },
  {
    header: {
      label: "Sold out",
      order: [2, 1],
      title: "Victor Horta",
      subtitle:
        "Composing music based on “how to translate Victor Horta’s Art Nouveau whirls and colours into our electronic music?”",
      image: {
        src: VictorHorta,
        alt: "Victor Horta",
      },
    },
  },
  {
    header: {
      label: "Sold out",
      order: [1, 2],
      title: "III Hotel",
      subtitle:
        "By identifying the only U-shaped hotel in Belgium and building the stage on the ancient pool and, we created the feeling of an amphitheater. Imagine people dancing with their friends on the balconies, while staying for the night at this protected heritage hotel.",
      image: {
        src: IIIHotel,
        alt: "III Hotel",
      },
    },
  },

  {
    header: {
      label: "Sold out",
      order: [2, 1],
      title: "III Amsterdam",
      subtitle:
        "Set up in the city centre of Amsterdam, in a futuristic design hotel. We created this amphitheater so III guests can dance in times of full lockdown: using balconies as the steep slope, towing a boat to the harbour as the stage, and installing a high quality audio system in all the rooms.",
      image: {
        src: IIIAmsterdam,
        alt: "III Amsterdam",
      },
    },
  },
];

export default shows;
