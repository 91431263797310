/*
 * iiimagine-frontend - SingleShow.component.tsx
 * SingleShow.component.tsx
 *
 * Created by Renzo Roso (renzo@3tdev.nl)
 * Copyright 3T Development - 2022-01-31
 */

import classNames from "classnames";
import { useCallback, useMemo, useState } from "react";
import { FaPlay } from "react-icons/fa";
import ReactPlayer from "react-player/file";
import BirdPrefixTitleComponent from "../BirdPrefixTitle/BirdPrefixTitle.component";
import FullScreen from "../FullScreen/FullScreen.component";
import ReadMoreComponent from "../ReadMore/ReadMore.component";
import SmudgeBannerComponent from "../SmudgeBanner/SmudgeBanner.component";
import {
  PreviousShow,
  isImageVertical,
  isShowGallery,
  isShowImage,
  isShowVideo,
} from "./previousShows";
import Gallery from "../Gallery";

interface SingleShowProps {
  show: PreviousShow;
  index: number;
}

export default function SingleShowComponent({ show, index }: SingleShowProps) {
  const [showingVideo, updateShowingVideo] = useState<boolean>(false);

  const showVideo = useCallback(() => {
    updateShowingVideo(true);
  }, []);

  const getMdOrder = useCallback((input) => {
    switch (input) {
      case 1:
        return "md:order-1";
      case 2:
        return "md:order-2";
    }
  }, []);

  const mediaComponent = useMemo(() => {
    if (isShowGallery(show)) {
      return (
        <div className={`relative after:absolute previousShowPreview`}>
          <Gallery images={show.header.images} />
        </div>
      );
    } else if (isShowImage(show)) {
      return (
        <div className={`relative after:absolute previousShowPreview`}>
          <img
            src={show.header.image.src}
            alt={show.header.image.alt}
            className={`rounded-xl w-full`}
          />
        </div>
      );
    } else if (isShowVideo(show) && !showingVideo)
      return (
        <div
          className={`relative after:absolute previousShowPreview`}
          onClick={showVideo}
        >
          <div className="w-full h-full absolute flex justify-center items-center">
            <FaPlay className="fill-white z-50 absolute" />
          </div>
          <img
            src={show.header.video.thumbnail}
            alt={show.header.video.alt}
            className={`rounded-xl w-full`}
          />
        </div>
      );

    return (
      <div className={"aspect-video w-full md:w-[90%]"}>
        <ReactPlayer
          width={"100%"}
          height={"100%"}
          url={show.header.video.url}
          playing
          controls
        />
      </div>
    );
  }, [show, showVideo, showingVideo]);

  return (
    <FullScreen containerClassName={"py-4"}>
      <div className={""}>
        <div className="flex flex-col md:flex-row gap-8">
          <div
            className={classNames(
              "flex-1 flex-col justify-start flex gap-4",
              getMdOrder(show.header.order[0])
            )}
          >
            {show.header.label && <SmudgeBannerComponent type={"sold-out"} />}
            <BirdPrefixTitleComponent
              prefix={index + 1 < 10 ? `0${index + 1}.` : `${index}.`}
              title={show.header.title}
            />
            <p className="text-lg md:text-xl mt-1">{show.header.subtitle}</p>
            {((isShowImage(show) && isImageVertical(show)) ||
              isShowGallery(show)) &&
              show.content && (
                <div className={"py-8"}>
                  <ReadMoreComponent
                    className="md:flex-col"
                    more={show.content.more}
                  >
                    {show.content.main}
                  </ReadMoreComponent>
                </div>
              )}
          </div>
          <div
            className={classNames(
              "flex-1 w-full md:w-1/2 justify-center items-center",
              getMdOrder(show.header.order[1])
            )}
          >
            {mediaComponent}
          </div>
        </div>
      </div>
      {!isShowGallery(show) &&
        (!isShowImage(show) || !isImageVertical(show)) &&
        show.content && (
          <div className={"py-8"}>
            <ReadMoreComponent more={show.content.more}>
              {show.content.main}
            </ReadMoreComponent>
          </div>
        )}
    </FullScreen>
  );
}
