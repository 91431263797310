/*
 * iiimagine-frontend - PreviousShows.component.tsx
 * PreviousShows.component.tsx
 *
 * Created by Renzo Roso (renzo@3tdev.nl)
 * Copyright 3T Development - 2022-01-27
 */

import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { addEveryOther } from "../../lib/Arr";
import ContentDividerImageComponent from "../ContentDividerImage/ContentDividerImage.component";
import WhiteButtonComponent from "../WhiteButton/WhiteButton.component";
import previousShows, { PreviousShow, PreviousShows } from "./previousShows";
import SingleShowComponent from "./SingleShow.component";

export default function PreviousShowsComponent() {
  const [isOpened, updateIsOpened] = useState(false);

  const toggleOpen = useCallback(() => {
    updateIsOpened(!isOpened);
  }, [isOpened]);

  const shows = useMemo(() => {
    const showCopy: PreviousShows = [...previousShows];
    if (showCopy.length === 0) return <></>;

    const viewableShowList: PreviousShows = [];
    viewableShowList.push(showCopy.shift()!);
    if (showCopy.length > 0) viewableShowList.push(showCopy.shift()!);
    if (showCopy.length > 0) viewableShowList.push(showCopy.shift()!);
    if (showCopy.length > 0) viewableShowList.push(showCopy.shift()!);
    if (showCopy.length > 0) viewableShowList.push(showCopy.shift()!); // Added line to show 5 shows instead of 4

    function generateShow(startIndex: number) {
      return function (show: PreviousShow, index: number) {
        return <SingleShowComponent show={show} index={startIndex + index} />;
      };
    }

    return (
      <>
        <div>
          {addEveryOther(
            viewableShowList.map(generateShow(0)),
            <ContentDividerImageComponent />
          )}
        </div>
        <ContentDividerImageComponent />
        <div
          className={classNames(
            "relative transition-[max-height] ease-in-out duration-500 overflow-y-hidden flex flex-col gap-8 max-h-0",
            "flex flex-col gap-2",
            isOpened && "!max-h-[500rem]"
          )}
        >
          {addEveryOther(
            showCopy.map(generateShow(viewableShowList.length)),
            <ContentDividerImageComponent />
          )}
          <div className="w-full md:hidden py-4" />
        </div>
      </>
    );
  }, [isOpened]);

  return (
    <>
      {shows}
      <div
        className={
          "w-full bg-black flex justify-center md:pt-8 pb-8 px-8 container mx-auto"
        }
      >
        <WhiteButtonComponent onClick={toggleOpen} className="w-full md:w-fit">
          :: VIEW {isOpened ? "LESS" : "MORE"} SHOWS ::
        </WhiteButtonComponent>
      </div>
    </>
  );
}
