/*
 * iiimagine-frontend - ContentDividerImage.component.tsx
 * ContentDividerImage.component.tsx
 *
 * Created by Renzo Roso (renzo@3tdev.nl)
 * Copyright 3T Development - 2022-01-26
 */

import React from 'react';
import ContentDivider from '../../assets/images/Content-Divider.png';

export default function ContentDividerImageComponent() {

    return (
        <div className={"bg-black hidden md:flex justify-center items-center md:py-12 lg:py-12"}>
            <img src={ContentDivider} alt="content-divider" className="hidden md:block w-auto h-16"/>
        </div>
    )
};
