import * as React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface GalleryProps {
  images: {
    src: string;
    alt: string;
  }[];
}

const Gallery: React.FC<GalleryProps> = ({ images }) => {
  return (
    <Slider
      dots={false}
      infinite={true}
      speed={500}
      slidesToShow={1}
      slidesToScroll={1}
      className="gallery-slider"
      prevArrow={
        <svg width={16} height={28} viewBox="0 0 8 14" fill="none">
          <path
            d="M0.500195 7.7998L6.2002 13.3998C6.6002 13.7998 7.2002 13.7998 7.6002 13.3998C8.0002 12.9998 8.0002 12.3998 7.6002 11.9998L2.7002 6.9998L7.6002 1.9998C8.0002 1.5998 8.0002 0.999804 7.6002 0.599804C7.4002 0.399804 7.2002 0.299805 6.9002 0.299805C6.6002 0.299805 6.4002 0.399804 6.2002 0.599804L0.500195 6.1998C0.100195 6.6998 0.100195 7.2998 0.500195 7.7998C0.500195 7.6998 0.500195 7.6998 0.500195 7.7998Z"
            fill="#ffffff80"
          />
        </svg>
      }
      nextArrow={
        <svg width={16} height={28} viewBox="0 0 8 14" fill="none">
          <path
            d="M7.53974 6.28955L1.87974 0.639548C1.78677 0.54582 1.67617 0.471426 1.55431 0.420657C1.43246 0.369888 1.30175 0.34375 1.16974 0.34375C1.03773 0.34375 0.90702 0.369888 0.785161 0.420657C0.663302 0.471426 0.552701 0.54582 0.459738 0.639548C0.273487 0.826911 0.168945 1.08036 0.168945 1.34455C0.168945 1.60873 0.273487 1.86219 0.459738 2.04955L5.40974 7.04955L0.459738 11.9995C0.273487 12.1869 0.168945 12.4404 0.168945 12.7045C0.168945 12.9687 0.273487 13.2222 0.459738 13.4095C0.552352 13.504 0.662797 13.5792 0.784673 13.6307C0.906548 13.6822 1.03743 13.709 1.16974 13.7095C1.30205 13.709 1.43293 13.6822 1.5548 13.6307C1.67668 13.5792 1.78712 13.504 1.87974 13.4095L7.53974 7.75955C7.64124 7.66591 7.72225 7.55225 7.77766 7.42576C7.83307 7.29926 7.86167 7.16265 7.86167 7.02455C7.86167 6.88645 7.83307 6.74984 7.77766 6.62334C7.72225 6.49684 7.64124 6.38319 7.53974 6.28955Z"
            fill="#ffffff80"
          />
        </svg>
      }
    >
      {images.map((image) => (
        <div key={`gall-image-${image.src}`}>
          <img
            src={image.src}
            alt={image.alt}
            className={`rounded-xl w-full`}
          />
        </div>
      ))}
    </Slider>
  );
};
export default Gallery;
