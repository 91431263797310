/*
 * iiimagine-frontend - MenuBar.component.tsx
 * MenuBar.component.tsx
 *
 * Created by Renzo Roso (renzo@3tdev.nl)
 * Copyright 3T Development - 2022-01-26
 */

import React, {useMemo} from 'react';
import {addEveryOther} from "../../lib/Arr";

interface MenuItem {
    title: string;
    link: string;
}

interface MenuBarComponentProps {
    menu: MenuItem[];
}

export default function MenuBarComponent({menu = []}: MenuBarComponentProps) {
    const menuItems = useMemo(() => {
        const copy = menu.map((item) => (
            <a href={item.link} key={`menu-${item.title}`} className={'text-white'}>{item.title}</a>
        ));

        return addEveryOther(copy, <span className={'text-iiimagine-red'}>◉</span>)
    }, [menu])

    return (
        <div className={"absolute top-8 md:top-12 w-screen lg:w-full z-50"}>
            <div className="container mx-auto">
                <div className="px-8 md:px-12 lg:px-8 flex md:justify-end">
                    <div className={"w-content flex gap-6 md:gap-4"}>
                        {menuItems}
                    </div>
                </div>
            </div>
        </div>
    )
};
